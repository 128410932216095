import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Iservice from "../elements/Iservice";

const iservicesData = [
  {
    id: 1,
    item1: "Python",
    item2:"C, C#, C++",
    item3:"Matlab",
    color: "#6C6CE5",
    contentColor: "dark",
  },
  {
    id: 2,
    item1: "Git",
    item2:"VHDL",
    item3:"LaTeX",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    item1: "Java",
    item2:"Unity",
    item3:"Photoshop",
    color: "#F97B8B",
    contentColor: "dark",
  },
];

function Interests() {
  return (
    <section id="iservices">
      <div className="container">
        <Pagetitle title="Skills" />
        <div className="row fix-spacing">
          {iservicesData.map((iservice) => (
            <div className="col-md-4" key={iservice.id}>
              <Iservice iservice={iservice} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Interests;

import React from "react";

function Iservice({ iservice }) {
  const { item1, item2 , item3, color, contentColor } = iservice;
  const hexToRgb = (hex) => {
    const temp = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    return temp.join(",");
  };

  return (
    <div
      className={
        contentColor === "light"
          ? "service-box rounded data-background padding-30 text-center text-light shadow-blue"
          : "service-box rounded data-background padding-30 text-center shadow-blue"
      }
      data-color="#6C6CE5"
      style={{
        background: color,
        boxShadow: `0px 5px 20px 0px rgba(${hexToRgb(color)}, 0.5)`,
      }}
    >
      <h3 className="mb-3 mt-0">{item1}</h3>
      <h3 className="mb-3 mt-0">{item2}</h3>
      <h3 className="mb-3 mt-0">{item3}</h3>
    </div>
  );
}

export default Iservice;

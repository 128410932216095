//import React, { useState } from "react";
import React, { useState, useEffect, createContext } from 'react';
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header from "../components/layouts/Header";
import About from "../components/sections/About";
import Brandlogos from "../components/sections/Brandlogos";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
import Pricing from "../components/sections/Pricing";
import Hobbies from "../components/sections/Hobbies";
import Testimonials from "../components/sections/Testimonials";
import Works from "../components/sections/Works";
import { useMediaPredicate } from "react-media-hook";
import Blogs from '../components/sections/Blogs';
import Projects from '../components/sections/Projects';
import Interests from '../components/sections/Interests';
function Homepage() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  const darkModeToggler = (e) => {
    e.preventDefault();
    localStorage.setItem('dark',!dark?1:0);
    console.log(!dark);
    setDark(!dark);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content-2")) {
      setToggleMenu(false);
      console.log("test")
    }
  });




  var darkMode = (localStorage.getItem('dark')=="1")? true : false;
    const preferredColor = useMediaPredicate("(prefers-color-scheme: dark)") ? true : false;
    if(darkMode == null){
      darkMode = preferredColor;
    }
    if(darkMode){
      document.body.classList.add("dark");
    }

    useEffect(() => {
      if(dark){
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    });

    const [dark, setDark] = useState(darkMode);

    function handleClick(e) {
      e.preventDefault();
      localStorage.setItem('dark',!dark?1:0);
      console.log(!dark);
      setDark(!dark);
    }

  return (
    <>
      <Header
        light={!dark}
        logoSource={dark?"/images/logo-b-light.svg":"/images/logo-b-dark.svg"}

        darkModeToggler={darkModeToggler}
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content-2 open" : "content-2"}>
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection light={!dark} />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          <About />
        </Element>
        <Element name="section-experiences">
          <Experiences />
        </Element>
        <Element name="section-iservices">
        <Interests />
       </Element>
        {/* <Element name="section-brandlogos">
         <Brandlogos />
        </Element> */}
        {/* <Element name="section-projects">
            <Projects />
        </Element> */}
        <Element name="section-contact">
          <Contact />
        </Element>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Homepage;

import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Hashim",
  avatarImage: "/images/CV-pic.png",
  content:
    "While pursuing a BSc in Electrical Engineering, I gained valuable experience with a range of technologies and programming languages including \
    Python, Matlab, C, C++, and VHDL. Additionally, I pursued a minor in Computer Science, which has provided me with a solid foundation in the field.",
};

const progressData = [
  {
    id: 1,
    title: "Hardworker",
    percentage: 80,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Quicklearner/Adaptable",
    percentage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Communication/teamwork",
    percentage: 70,
    progressColor: "#6C6CE5",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projects completed",
    count: 6,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Cup of coffee",
    count: Math.random()*10000,
    icon: "icon-cup",
  },
  // {
  //   id: 3,
  //   title: "Satisfied clients",
  //   count: 427,
  //   icon: "icon-people",
  // },
  // {
  //   id: 4,
  //   title: "Nominees winner",
  //   count: 35,
  //   icon: "icon-badge",
  // },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  {/*<div className="mt-3">*/}
                  {/*  <a href="https://doc-0o-bk-docs.googleusercontent.com/docs/securesc/ol917ite3iiogddtco4j64ch9ds79pf0/hc4vvp4bdbm4ug7vmf9k9kohtkomq6tg/1612287750000/05515008958444369068/05515008958444369068/1CFT1W3agOf-9lZZrgrsTI38afFWMHCuA?e=download&authuser=1&nonce=tmputgrnn0lcc&user=05515008958444369068&hash=gsu9iabalkav943i6ignrm1lh0p4pk6i" className="btn btn-default">*/}
                  {/*    Download CV*/}
                  {/*  </a>*/}
                  {/*</div>*/}
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;

import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "TU Delft, BSc, Electrical Engineering",
    years: "2019 - Present",
    content:
      "Additonal Courses: [EE2ML11] Introduction to machine learning",
  },
  {
    id: 2,
    title: "TU Delft, Minor, Computer Science",
    years: "2021 - 2022",
    content:
      "Grade: 8.25/10",
  },
  {
    id: 3,
    title: "TU Delft, BSc, Computer Science and Engineering",
    years: "2018 - 2019",
    content:
      "Did one year before switching to Electrical Engineering in the next academic year",
  },
  {
    id: 4,
    title: "Highschool Diploma with Honours",
    years: "2013 - 2018",
    content:
      "International School of Chouiefat Manama",
  },
];

const experienceData = [
  {
    id: 1,
    title: "D.S.B.V. Punch - Game Commissioner",
    years: "2022 - 2023",
    content:
      "Served as Game Commissioner for DSBV Punch during the 2022-2023 season, overseeing all aspects of game scheduling and match day logistics for the organization.",
  }, 
  {
    id: 2,
    title: "D.S.B.V. Punch - Chairman Tech & Media Committee",
    years: "2021 - Present",
    content:
      "Manage the IT infrastructure for D.S.B.V. Punch",
  },
  {
    id: 3,
    title: "D.S.B.V. Punch - Committee member",
    years: "2021 - Present",
    content:
      "Participated in various commitees: Activities, International Tournament, Lustrum and Merch",
  },
  {
    id: 4,
    title: "Freelance Web Developer",
    years: "2019",
    content:
      "Created site: https://www.lionsignsblinds.co.uk/",
  },
  {
    id: 5,
    title: "Freelance Web Developer",
    years: "2019",
    content:
      "Created site: https://blindsempire.co.uk/",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
